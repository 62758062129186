import equitasLogo from './logo_equitas.png'
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={equitasLogo} className="App-logo" alt="logo" />
        <p>
          The Equitas App is undergoing maintenance on Sunday 8 December 2024
        </p>
        <p>
          We'll be back on Monday 9th at 5 am (GMT time)
        </p>
      </header>
    </div>
  );
}

export default App;
